.tournamentContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.tournament {
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 150px;
}


.tournamentHeader {
  text-align: center;
  margin-bottom: 20px;
}

.joinNowButton {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.joinNowButton:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.infoContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
}

.infoBox {
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.infoBox p {
  background-color: #4caf50;
  padding: 5px 20px;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.infoBox:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.infoBox p {
  margin: 0;
  font-size: 14px;
}

.infoBox span {
  display: block;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding: 5px 20px;
}


.tournamentContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}



.tournamentHeader {
  text-align: center;
  margin-bottom: 20px;
}

.joinNowButton {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.joinNowButton:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.infoContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  gap: 15px;
}

.infoBox {
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.infoBox:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.prizeContainer {
  margin-top: 20px;
}

.prizes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
}

.prizeBox {
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f7f7f7;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.prizeBox:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.prizeBox p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.prizeBox span {
  display: block;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.font-design {
  font-weight: 600;
}

.tabButton {
  padding: 10px;
}

.tabButton button {
  padding: 10px;
  background: transparent;
}

.activeTab {
  background-color: transparent;
  color: #652287;
  font-weight: 700;
  border-bottom: 2px solid #652287 !important;
}


.tournament-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0 !important;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4caf50 !important;
  transition: width 0.3s ease;
}

