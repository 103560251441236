@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --primary_color: #005556;
  --secondary_color: #005b7e;
}

body {
  font-family: "Roboto", sans-serif !important;
}

.bg-1 {
  background: var(--primary_color);
}

#hambergar:hover {
  background-color: transparent !important;
}

.sideNav-divider {
  background-color: #ededed;
  bottom: 0;
  height: 1px;
  left: 72px;
  position: absolute;
  right: 0;
}

.sideNav-arrow {
  position: absolute;
  right: 25px;
}

.sideNav-arrow>img {
  height: 11px;
  max-width: 100%;
  width: 6px;
  filter: invert(1);
}

.profile_box {
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  background: #4a1b63;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  color: #fff;
}

.profile_img {
  display: flex;
}

.profile_pic {
  border-radius: 50px;
  margin-right: -15px;
  z-index: 1;
  position: relative;
}

.details {
  position: relative;
  border-radius: 15px;
  border: 1px solid #fff;
  background: linear-gradient(45deg, #7951e5, #3bb7af);
  padding: 3px 8px;
  margin-bottom: 10px;
  font-size: 16px;

  i {
    border-radius: 5px;
    padding: 5px;
  }
}

.battle {
  display: flex;
  justify-content: space-around;
}

.phone {
  background: #41a5ee;
}

.mail {
  background: #ff2147;
}

.kyc {
  background: #ff9100;
}

.bell {
  padding: 10px;
}

.bell .fa-bell {
  border: 1px solid;
  border-radius: 50%;
  padding: 8px;
  font-size: 1.2rem;
  color: #fbc905;
}

.profile_box span {
  font-size: 13px;
  font-weight: 600;
}

.profile_box i {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
}

.edit_profile {
  background: #fff;
  border-radius: 10px;
  padding: 5px 8px;
  cursor: pointer;

  span {
    font-size: 13px;
    font-weight: 700;
    margin-left: 8px;
  }
}

.wallet_box {
  .boxes {
    background: #c8c8c8;
    color: #fff;
    padding: 2px 10px;
    border-radius: 10px;
  }
}

.battle_info {
  display: flex;
  justify-content: center;
  width: 50%;

  .boxes {
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #fff;
    min-height: 140px;
    text-align: center;
    margin-bottom: 15px;

    .undefined {
      color: #fff;
    }
  }
}

.log_out {
  background: #c70000;
  border-radius: 20px;
  padding: 5px 20px;
}

.bg-btn {
  background: linear-gradient(45deg, #7951e5, #3bb7af);
}

.card {
  font-family: "Roboto", sans-serif;
}

.info {
  color: #ebcd51 !important;
}

.back_btn {
  .btn {
    padding: 10px 20px !important;
    background: linear-gradient(45deg, #7951e5, #3bb7af);
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 5px;
  }
}

.page-item {
  a.page-link {
    color: #000 !important;
  }
}

.bg-none {
  background: none;
}

.border_danger {
  border-color: #690202;
}

.t_history {
  background: #4a1b63;
  border: 1px solid #dd9eff;
  border-radius: 12px;
}

.w3-button {
  /* background-color:blueviolet; */
  color: white;
  /* position:fixed */
  margin: auto;
  right: 0;
  top: 20px;
  /* right:/20px; */
  padding: 0.25rem;
  transition: transform 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}

.w3-button.active {
  transform: translateX(-100px);
}

.w3-sidebar {
  width: 65%;
  max-width: 300px;
  height: 100%;
  background: #490f7f;
  color: black !important;
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  transition: all 0.5s;
  z-index: 99 !important;
  font-weight: 600;
  border-right: 1px solid #a441ff;
}

.w3-container {
  padding: 0px;
  top: 0 !important;
  margin: auto;
  background: #fff !important;
}

.w3-teal {
  background: white !important;
}

.w3-bar-block .w3-bar-item {
  height: 50px;
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  color: #fff !important;
  margin-bottom: 10px;
  padding-left: 20px;
}

.w3-bar-block .w3-bar-item:hover {
  background: linear-gradient(45deg, #8c12dd, #0f81ca);
}

.far {
  font-size: 1.3rem;
}

.arrow {
  position: absolute;
  right: 25px;
}

.arrow>img {
  height: 20px;
  width: 6px;
  max-width: 100%;
}

.icon {
  overflow: hidden;
  position: relative;
}

.icon>img {
  height: 30px;
  width: 30px;
  /* max-width: 100%; */
}

#sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none;
  transition: all 0.5s ease-in-out 0s;
}

.swal2-popup {
  background: #003441;
  color: #fff;
}


/* landing.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(90deg, #873aad, #230b83db 47%, #7c19ffc7) !important;
  padding: 8px 20px 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
}

.close-button {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

body.modal-active {
  overflow: hidden;
}

.new_five {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}

#modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0px;
  margin: auto;
  left: 0px;
  transform: scale(0);
  z-index: 999;
  background: #000000bf;

  &.one {
    transform: scaleY(.01) scaleX(0);
    animation: unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

    .modal-background {
      .modal-content {
        display: block;
      }

      .modal {
        transform: scale(0);
        animation: zoomIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }

    &.out {
      transform: scale(1);
      animation: unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

      .modal-background {
        .modal {
          animation: zoomOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
    }
  }

  &.two {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

      .modal {
        opacity: 0;
        animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }

    +.content {
      animation: scaleBack .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;

      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .modal {
          animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }

      +.content {
        animation: scaleForward .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }

  &.three {
    z-index: 0;
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .6);

      .modal {
        animation: moveUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }

    +.content {
      z-index: 1;
      animation: slideUpLarge .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    &.out {
      .modal-background {
        .modal {
          animation: moveDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }

      +.content {
        animation: slideDownLarge .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }

  &.four {
    z-index: 0;
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .7);

      .modal {
        animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }

    +.content {
      z-index: 1;
      animation: blowUpContent .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    &.out {
      .modal-background {
        .modal {
          animation: blowUpModalTwo .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }

      +.content {
        animation: blowUpContentTwo .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }

  &.five {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      .modal {
        transform: translateX(-1500px);
        animation: roadRunnerIn .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;

      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .modal {
          animation: roadRunnerOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
    }
  }

  &.six {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

      .modal {
        background-color: transparent;
        animation: modalFadeIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        h2,
        p {
          opacity: 0;
          position: relative;
          animation: modalContentFadeIn .5s 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }

        .modal-svg {
          rect {
            animation: sketchIn .5s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }
        }
      }
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;

      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .modal {
          animation: modalFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

          h2,
          p {
            animation: modalContentFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }

          .modal-svg {
            rect {
              animation: sketchOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }
          }
        }
      }
    }
  }

  &.seven {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

      .modal {
        height: 75px;
        width: 75px;
        border-radius: 75px;
        overflow: hidden;
        animation: bondJamesBond 1.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        h2,
        p {
          opacity: 0;
          position: relative;
          animation: modalContentFadeIn .5s 1.4s linear forwards;
        }
      }
    }

    &.out {
      animation: slowFade .5s 1.5s linear forwards;

      .modal-background {
        background-color: rgba(0, 0, 0, .7);
        animation: fadeToRed 2s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .modal {
          border-radius: 3px;
          height: 162px;
          width: 227px;
          animation: killShot 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

          h2,
          p {
            animation: modalContentFadeOut .5s .5 cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }
        }
      }
    }
  }

  .updateversion {
    font-size: 18px;
    margin: 15px auto;
    color: #f44336;
    font-weight: 700;
  }

  .updateversionsec {
    font-size: 18px;
  }

  .modal-background {
    display: table-cell;
    background: rgba(0, 0, 0, .8);
    text-align: center;
    vertical-align: middle;

    .modal {
      background: #fff;
      border-radius: 5px;
      font-weight: 300;
      position: relative;
      padding: 20px;
      width: 345px;
      display: flex;
      overflow: visible;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 64px;

      h2 {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 15px;
      }

      p {
        font-size: 18px;
        line-height: 22px;
      }

      .modal-svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 3px;

        rect {
          stroke: #fff;
          stroke-width: 2px;
          stroke-dasharray: 778;
          stroke-dashoffset: 778;
        }
      }
    }
  }
}

.logo_ludo {
  width: 150px;
}

#closemodle {
  position: absolute;
  right: 8px;
  top: 8px;
  border-radius: 5px;
  background: red;
  color: #fff;
  transform: scale(1.2);
  border: none;
}

.modal_title {
  font-size: 25px;
  color: #fff;
}

.modal_header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff;
  justify-content: space-between;
}

.updateversion_sec {

}

.updateversion_sec video, .updateversion_sec img  {
  margin-top: 10px;
  border: 1px solid;
  border-radius: 5px;
  padding: 10px 5px 0px;
}

